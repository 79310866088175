import classNames from 'classnames';
import { useId } from 'hooks';
import './index.css';

function Monogram({ highlight, className, ...props }) {
  const id = useId();
  const clipId = `monogram-clip-${id}`;

  return (
    <svg
      aria-hidden
      className={classNames('monogram', className)}
      width="46"
      height="29"
      viewBox="0 0 46 29"
      {...props}
    >
      <defs>
        <clipPath id={clipId}>
          <path d="M38.4,14.3c0,7.4-7,13.4-15.7,13.4s-15.7-6-15.7-13.4s7-13.4,15.7-13.4S38.4,6.9,38.4,14.3z M23.2,5.7
	c-1.6,0.1-3.1,0.7-4.3,1.6c-0.1,0.1-0.2,0.2-0.2,0.2c0,0-0.2,0-0.3,0.1c-1.6,0.3-2.9,1-4,1.9c-1.3,1.1-2,2.6-2.1,4.3
	c0,0.5,0,1.3,0.1,1.8c0.2,1.1,0.7,2.3,1.5,3.2c0.3,0.4,0.8,0.8,1.1,1.1c1.6,1.2,3.6,1.7,5.6,1.5c0.5-0.1,1.1-0.2,1.6-0.4
	c1-0.3,1.7-0.7,2.5-1.3c0.1-0.1,0.2-0.1,0.2-0.1c0,0,0,0.1,0,0.3c0,0.3,0,0.4,0.1,0.6c0.1,0.4,0.5,0.7,0.8,0.9
	c0.5,0.2,1.3,0.1,2-0.2c1.2-0.6,2.2-1.7,3.2-3.6c0.1-0.3,0.2-0.4,0.2-0.4c0,0,0.2,0.2,0.3,0.4c0,0.1,0.1,0.2,0.1,0.2
	c0,0,0.1,0,0.2,0c0.2,0,0.2-0.1,0.2-0.1c0-0.1-0.3-0.6-0.5-0.9c-0.1-0.1-0.1-0.1-0.1-0.1c0.3-0.8,0.4-1.4,0.5-2
	c0.1-0.7,0.2-1.6,0.1-2.4c-0.1-0.6-0.2-1.3-0.4-1.8c-0.3-0.8-0.7-1.6-1.3-2.2c-0.1-0.1-0.1-0.2-0.2-0.2l0,0l0.1,0
	c0.1-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.3-0.1,0.6-0.1c0.2,0,0.9,0,1,0c0.2,0,0.2,0,0.2-0.3l0-0.1l-0.1,0c-0.3-0.1-0.8-0.1-1.2,0
	c-0.5,0.1-0.9,0.2-1.4,0.5c-0.1,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1-0.1c-0.4-0.3-0.8-0.6-1.1-0.8c-0.9-0.6-2.2-1-3.6-1.1
	c-0.2,0-0.4,0-0.9,0C23.5,5.7,23.2,5.7,23.2,5.7z M24.4,6c0.5,0,0.9,0.1,1.4,0.2c0.6,0.1,1,0.3,1.5,0.5c0.5,0.2,0.9,0.5,1.3,0.8
	C28.8,7.6,29.2,8,29.2,8c0,0-0.1,0.1-0.2,0.2c-0.2,0.2-0.5,0.5-0.8,0.7c-1,1-1.7,2.4-2.7,5.4c-0.3,0.7-0.6,1.6-0.7,2
	c-0.6,1.5-1.3,2.5-2.2,3.3C21.5,20.6,20.4,21,19,21c-1.1-0.1-2.1-0.4-3.1-1c-0.7-0.4-1.3-0.9-1.8-1.5c-0.8-1-1.2-2-1.4-3.2
	c0-0.3,0-1.1,0-1.4c0.1-0.8,0.3-1.5,0.7-2.2c0.4-0.7,0.9-1.3,1.6-2c0.9-0.8,1.6-1.2,2.8-1.5C18,8.1,18,8.1,18,8.1c0,0,0,0.1-0.1,0.2
	c-0.1,0.2-0.4,0.7-0.5,0.9c-0.1,0.2-0.2,0.5-0.2,0.7c0,0.1,0,0.3,0,0.6c0,0.4,0,0.5,0,0.6c0.2,0.8,0.6,1.5,1.2,2.1
	c0.4,0.3,0.7,0.5,1.1,0.7c0.4,0.2,0.7,0.3,1.2,0.3c0.2,0,0.9,0,1.2,0c0.9-0.1,1.6-0.4,2.5-1.1c0.1-0.1,0.2-0.1,0.2-0.1
	c0,0,0,0.1-0.1,0.4c-0.3,1.1-1.1,2.2-2.1,2.8c-0.5,0.4-1.2,0.6-1.7,0.8c-0.8,0.2-1.7,0.1-2.4-0.2c-1-0.4-1.8-1.3-2-2.2
	c-0.1-0.5-0.1-0.9,0.1-1.6c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.2,0.6-0.2,0.8c0,0.2-0.1,0.7,0,0.9
	c0.1,0.8,0.5,1.5,1.1,2c0.5,0.5,1.3,0.9,2,1c0.4,0.1,0.6,0.1,1.1,0.1c0.5,0,0.6,0,1-0.1c1.3-0.2,2.5-1,3.2-2.1
	c0.1-0.2,0.4-0.6,0.5-0.9c0.3-0.6,0.4-1.1,0.5-2l0-0.2l0.1-0.2c0.4-0.6,0.7-1.3,0.8-2c0.1-0.7,0.1-1.4-0.2-2C26,8.2,26,8.2,26,8.2
	c0,0-0.1,0-0.2,0c-0.2,0.1-0.2,0.1-0.1,0.3c0.1,0.5,0.2,0.7,0.2,1.2c0,0.7-0.1,1.1-0.4,1.7c-0.1,0.2-0.1,0.3-0.2,0.3
	c0,0,0-0.1-0.1-0.2c-0.2-1.1-0.6-1.8-1.4-2.5c-0.7-0.7-1.5-1.1-2.4-1.3c-0.5-0.1-1.3-0.2-1.7-0.2c-0.2,0-0.3,0-0.3,0
	c0,0,0.3-0.2,0.6-0.4c0.8-0.5,2.2-0.9,3.4-1C23.6,6,24.2,6,24.4,6z M20.1,7.9c0.5,0,1.2,0.2,1.7,0.4c1,0.4,1.9,1.1,2.4,1.9
	c0.3,0.6,0.6,1.3,0.6,2.2l0,0.2l-0.2,0.2c-0.8,0.7-1.6,1.2-2.5,1.3c-0.3,0-0.9,0.1-1.1,0c-1.3-0.1-2.4-0.8-3-1.9
	c-0.3-0.5-0.4-0.9-0.4-1.5c0-0.7,0.2-1.3,0.5-1.8c0.2-0.3,0.6-0.9,0.7-0.9c0,0,0.4,0,0.6-0.1C19.7,7.8,19.8,7.8,20.1,7.9z M29.8,8.6
	c0.5,0.7,0.9,1.4,1.1,2.1c0.3,0.9,0.3,2.1,0.2,3.3c-0.1,0.6-0.2,1.2-0.4,1.9c-0.1,0.3-0.1,0.3-0.1,0.3c0,0-0.1,0-0.2,0
	c-0.6-0.2-1.1-0.3-1.6-0.2c-0.7,0-1.3,0.2-2,0.7c-0.1,0.1-0.2,0.1-0.2,0.1c0,0,0,0,0-0.2c0.2-0.6,0.5-1.5,0.8-2.9
	c0.4-1.6,0.5-2.1,0.9-3c0.2-0.6,0.3-0.9,0.5-1.2c0.2-0.4,0.5-0.8,0.8-1C29.6,8.4,29.6,8.4,29.8,8.6z M29.2,16.4
	c0.3,0,0.8,0.1,1.1,0.3c0.1,0,0.1,0,0.1,0.1c-0.1,0.2-0.6,1.4-0.8,1.8c-0.4,0.7-0.8,1.3-1.3,1.7c-0.6,0.5-1.2,0.8-1.8,0.8
	c-0.4,0-0.6-0.1-0.9-0.3c-0.3-0.2-0.4-0.5-0.4-0.9c0-0.5,0.2-1,0.6-1.6c0.4-0.6,1-1.1,1.7-1.5C28.1,16.5,28.6,16.3,29.2,16.4z" />
        </clipPath>
      </defs>
      <rect clipPath={`url(#${clipId})`} width="100%" height="100%" />
      {highlight && (
        <g clipPath={`url(#${clipId})`}>
          <rect className="monogram__highlight" width="100%" height="100%" />
        </g>
      )}
    </svg>
  );
}

export default Monogram;
